
import {
    createApp
} from 'vue'
import App from './App.vue'

const app = createApp(App)
app.mount('#app')



// app.use(VXETablePluginExportXLSX)

window.vue = app;